import React, { Component } from "react";
import Modal from "react-modal";
import Tone from "tone";

import { startForwardAnimation12, startBackwardAnimation12 } from "./settingsBar";
import {  ModalTypeWriter } from "./dialogueBar";
import { typeSound, ampenvType } from "./tone";

Modal.setAppElement('#root')

export default class InfoModal extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            modalIsOpen: true
        }
      }


    render() {  
    
    return  <div class="info">
                      <span id="infoModalTitle">INSTRUCTIONS</span>
                      <br />
                      <br />
                      <br />
                      <img src={require("../Images/airbrush_cursor.png")} /> <span class="infoModalTextSmall">Use this tool to draw your response</span>
                      <br />
                      <br />
                      <img src={require("../Images/Buttons/butt01.png")} /> <span class="infoModalTextSmall">Select different preset palettes from the Methods</span>
                      <br />
                      <br />
                      <img id="simimg" src={require("../Images/Buttons/buttsim.png")} /> <span class="infoModalText">Process image after drawing response</span>
                      <br />
                      <br />
                      <img id="questimg" src={require("../Images/Buttons/buttquest.png")} /> <span class="infoModalText">Proceed on to the next question </span>
            </div>;
    };

    setModalIsOpen(){
        this.setState(
          {
            modalIsOpen: false
          }
        ) 
      }
  
  }
  