export const tileSheetExport = [
    require("../Images/TileSheets/gts01.png"),
    require("../Images/TileSheets/gts02.png"),
    require("../Images/TileSheets/gts03.png"),
    require("../Images/TileSheets/gts04.png"),
    require("../Images/TileSheets/gts05.png"),
    require("../Images/TileSheets/gts06.png"),
    require("../Images/TileSheets/gts07.png"),
    require("../Images/TileSheets/gts08.png"),
    require("../Images/TileSheets/gts09.png"),
    require("../Images/TileSheets/gts10.png"),
    require("../Images/TileSheets/gts11.png"),
    require("../Images/TileSheets/gts12.png"),
    require("../Images/TileSheets/gts13.png"),
    require("../Images/TileSheets/gts14.png"),
    require("../Images/TileSheets/gts15.png"),
    require("../Images/TileSheets/gts16.png"),
    require("../Images/TileSheets/gts17.png"),
    require("../Images/TileSheets/gts18.png"),
    require("../Images/TileSheets/gts19.png"),
    require("../Images/TileSheets/gts20.png"),
    require("../Images/TileSheets/gts21.png"),
    require("../Images/TileSheets/gts22.png"),
    require("../Images/TileSheets/gts23.png"),
    require("../Images/TileSheets/gts24.png"),
    require("../Images/TileSheets/gts25.png"),
    require("../Images/TileSheets/gts26.png"),
    require("../Images/TileSheets/gts27.png"),
    require("../Images/TileSheets/gts28.png"),
    require("../Images/TileSheets/gts29.png"),
    require("../Images/TileSheets/gts30.png"),
    require("../Images/TileSheets/gts31.png"),
    require("../Images/TileSheets/gts32.png"),
    require("../Images/TileSheets/gts33.png"),
    require("../Images/TileSheets/gts34.png"),
    require("../Images/TileSheets/gts35.png"),
    require("../Images/TileSheets/gts36.png"),
]

export const resourceExport = [
    require("../Images/Buttons/butt01.png"),
    require("../Images/Buttons/butt02.png"),
    require("../Images/Buttons/butt03.png"),
    require("../Images/Buttons/butt04.png"),
    require("../Images/Buttons/butt05.png"),
    require("../Images/Buttons/butt06.png"),
    require("../Images/Buttons/butt07.png"),
    require("../Images/Buttons/butt08.png"),
    require("../Images/Buttons/butt09.png"),
    require("../Images/Buttons/butt_cont.png"),
    require("../Images/Buttons/buttquest.png"),
    require("../Images/Buttons/buttsim.png"),
    require("../Images/Buttons/buttinst.png"),
    require("../Images/airbrush_cursor.png"),
    require("../Images/ganbgshift.png"),
    require("../Images/settingsbg.png"),
    require('../Sounds/buttonsounds01.wav'),
    require('../Sounds/buttonsounds02.wav'),
    require('../Sounds/buttonsounds03.wav'),
    require('../Sounds/buttonsounds04.wav'),
    require('../Sounds/buttonsounds05.wav'),
    require('../Sounds/buttonsounds06.wav'),
    require('../Sounds/buttonsounds07.wav'),
    require('../Sounds/buttonsounds08.wav'),
    require('../Sounds/buttonsounds09.wav'),
    require('../Sounds/buttonsounds10.wav'),
    require('../Sounds/buttonsounds11.wav'),
    require('../Sounds/buttonsounds12.wav'),
    require('../Sounds/FX-LSDtypewriter.wav'),
    require('../Sounds/startstop1.wav'),
    require('../Sounds/startstop2.wav'),

]
